import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Box } from "@mui/material";
import { Controller } from "react-hook-form";

const TextfieldController = ({
  autoComplete = "off",
  formatThousands = false,
  limitToTwoDecimalPlaces = false,
  limitToIntegersBetween1And40 = false,
  limitToAlphaNumericAndCapitalise = false,
  limitToLettersAndSpecialChars = false,
  ...props
}) => {
  const {
    control,
    name,
    defaultValue,
    fieldRef,
    placeholder,
    required,
    disabled,
    rows,
    handleSaveOnBlur,
    startAdornment,
    endAdornment,
    conditionalLabel,
    requiredLabel,
    pattern,
  } = props;





  const handleInputChange = (e, onChange) => {
    let { value } = e.target;

    if (limitToTwoDecimalPlaces) {
      // Replace all non-numeric characters and multiple periods
      value = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");

      // Split the value into two parts: before and after the decimal point
      const [integerPart, decimalPart] = value.split(".");

      // If the integer part is longer than 2 digits, slice it down to 2 digits
      if (integerPart && integerPart.length > 2) {
        value = integerPart.slice(0, 2) + (decimalPart ? "." + decimalPart : "");
      }

      // Limit decimal part to two digits
      if (decimalPart && decimalPart.length > 2) {
        value = integerPart + "." + decimalPart.substring(0, 2);
      }
    }

    if (limitToIntegersBetween1And40) {
      // Remove all non-numeric characters
      value = value.replace(/[^0-9]/g, "");

      // If the value is not a number, is less than 1, or is more than 40, or more than 2 digits, reset it
      const numericValue = Number(value);
      if (isNaN(numericValue) || numericValue < 1 || numericValue > 40 || value.length > 2) {
        value = "";
      }
    }

    if (limitToAlphaNumericAndCapitalise) {
      // Remove all non-alphanumeric characters and capitalise the letters
      value = value.replace(/[^0-9a-zA-Z ]/g, "").toUpperCase();

      // Trim spaces at the end if length is 7 or more
      if (value.length >= 8) {
        value = value.replace(/\s+$/, "");
      }

      // Limit the input to 7 characters
      value = value.substring(0, 8);
    }

    if (limitToLettersAndSpecialChars) {
      // Remove all non-letter characters (excluding hyphen and apostrophe), capitalise the letters
      value = value.replace(/[^A-Za-z\-' ]/g, "");

      // Limit the input to 50 characters
      value = value.substring(0, 25);
    }

    if (formatThousands) {
      const sanitizedValue = value.replace(/\D/g, "");
      const formattedValue = sanitizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      value = formattedValue;
    }

    e.target.value = value;
    onChange(e);
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onBlur, onChange, value }, fieldState }) => (
          <TextField
            size="small"
            ref={fieldRef}
            margin="dense"
            fullWidth
            autoComplete={autoComplete}
            label={conditionalLabel}
            variant="outlined"
            required={required}
            disabled={disabled}
            id={name}
            value={value || ""}
            placeholder={placeholder}
            onBlur={() => {
              onBlur();
              handleSaveOnBlur(name, value);
            }}
            onChange={(e) => handleInputChange(e, onChange)}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            multiline
            rows={rows}
            FormHelperTextProps={{
              sx: {
                mt: 0,
                mb: "0 !important",
                ml: "3px !important",
                mr: "3px !important",
                fontSize: "0.9rem !important",
                padding: "8px !important",
                fontWeight: "500 !important",
                color: "#ffffff !important",
                lineHeight: "normal !important",
                backgroundColor: "#A00202 !important",
              },
            }}
            InputProps={{
              endAdornment: endAdornment ? (
                <InputAdornment position="end">
                  <Box>{endAdornment}</Box>
                </InputAdornment>
              ) : null,
              startAdornment: startAdornment ? (
                <InputAdornment position="start">
                  <Box>{startAdornment}</Box>
                </InputAdornment>
              ) : null,
              sx: {
                "& input": {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
                "& fieldset": {
                  paddingTop: 0,
                },
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#222 !important",
              },
            }}
          />
        )}
        rules={{
          required: requiredLabel,
          pattern: { value: pattern, message: "Invalid input" },
        }}
      />
    </>
  );
};

TextfieldController.propTypes = {
  autoComplete: PropTypes.string,
  defaultValue: PropTypes.string,
  formatThousands: PropTypes.bool,
  limitToTwoDecimalPlaces: PropTypes.bool,
  limitToIntegersBetween1And40: PropTypes.bool,
  limitToAlphaNumericAndCapitalise: PropTypes.bool,
  limitToLettersAndSpecialChars: PropTypes.bool,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  localStorageValue: PropTypes.string,
  fieldRef: PropTypes.object,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  fieldMarginTop: PropTypes.number,
  fieldMarginBottom: PropTypes.number,
  handleSaveOnBlur: PropTypes.func,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  conditionalLabel: PropTypes.string,
  requiredLabel: PropTypes.string,
  pattern: PropTypes.instanceOf(RegExp),
};

export default TextfieldController;
