import React from "react";
import PropTypes from "prop-types";

//---- MUI -------------------------//
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// Declare the PropTypes for each prop
IconPopoverLink.propTypes = {
  handleOpenPopover: PropTypes.func.isRequired,
  handleClosePopover: PropTypes.func.isRequired,
};
//---- EXPORT DEFAULT FUNCTION -----//
export default function IconPopoverLink({
  handleOpenPopover,
  handleClosePopover,
}) {
  //--- PROPS ------------------------//

  //--- JSX --------------------------//
  return (
    <InfoOutlinedIcon
      fontSize="medium"
      onMouseEnter={handleOpenPopover}
      onMouseLeave={handleClosePopover}
      sx={{ color: "#D80080", position: "relative", top: "0.4rem" }}
    />
  );
}
