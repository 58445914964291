import React from "react";
import PropTypes from "prop-types";

// MUI
import Grid from "@mui/material/Grid";
import IconPopoverLink from "../popover/IconPopoverLink";
import Typography from "@mui/material/Typography";

// Function component
function DesktopGrid({ name, displayLabel, popoverContent, handleOpenPopover, handleClosePopover }) {
  return (
    <>
      <Grid item xs={11} sx={{ pt: 0 }}>
        <label htmlFor={name}>
          <Typography
            sx={{
              fontWeight: "500 !important",
              lineHeight: "2.5rem !important",
              color: "#4F145B !important",
              margin: "0 !important",
            }}
          >
            {displayLabel}
          </Typography>
        </label>
      </Grid>
      <Grid item xs={1} sx={{ pt: 0, textAlign: "center" }}>
        {popoverContent && (
          <IconPopoverLink
            handleOpenPopover={handleOpenPopover}
            handleClosePopover={handleClosePopover}
          />
        )}
      </Grid>
    </>
  );
}

// Prop types
DesktopGrid.propTypes = {
  name: PropTypes.string,
  displayLabel: PropTypes.string,
  popoverContent: PropTypes.string,
  handleOpenPopover: PropTypes.func,
  handleClosePopover: PropTypes.func,
};

export default DesktopGrid;
