import React from "react";
import PropTypes from "prop-types";

//---- MUI -------------------------//
import Typography from "@mui/material/Typography";

//---- EXPORT DEFAULT FUNCTION -----//
// Declare the PropTypes for each prop
MoreInfoPopoverLink.propTypes = {

  handleOpenPopover: PropTypes.func,
  handleClosePopover: PropTypes.func,
};
export default function MoreInfoPopoverLink({
 
  handleOpenPopover,
  handleClosePopover,
}) {
  //--- PROPS ------------------------//

  //--- SETTERS ----------------------//
  //--- CONSTANTS --------------------//
  //--- HANDLERS ---------------------//
  //--- CUSTOM HOOKS & USE EFFECT ----//
  //--- JSX --------------------------//
  return (
    <Typography
      style={{ display: "flex", alignItems: "center" }}
      fontSize="1rem"
      onMouseEnter={handleOpenPopover}
      onMouseLeave={handleClosePopover}
      
      sx={{
        color: "#D80080 !important",
        fontSize: "0.85rem !important",
        pt: "0.2 !important",
        mb: "0 !important",
      }}
    >
      More information
    </Typography>
  );
}
