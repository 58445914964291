import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import { DesktopContext } from "../../App";
import { CompactContext } from "../../App";
import StyledPopover from "../popover/StyledPopover";
import DesktopLabel from "../label/DesktopLabel";
import MobileLabel from "../label/MobileLabel";
import TextfieldControllerCompact from "./TextFieldControllerCompact";
import TextfieldController from "./TextFieldController";
import Grid from "@mui/material/Grid";
import { handleSaveOnBlur } from "../../utils/miscFunctions";

const TextFieldElement = ({
  autoComplete, 
  defaultValue,
  formatThousands = false,
  limitToTwoDecimalPlaces = false,
  limitToIntegersBetween1And40 = false,
  limitToAlphaNumericAndCapitalise = false,
  limitToLettersAndSpecialChars = false,
  popoverContent,
  startAdornment,
  endAdornment,
  name,
  control,
  label,
  required,
  placeholder,
  disabled,
  pattern,
  maxLength,
  rows,
  resolver,
}) => {
  const compact = useContext(CompactContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const fieldRef = useRef();
  const desktop = useContext(DesktopContext);

  var conditionalLabel = label;
  var displayLabel = label;
  var rightCol = 12;
  var fieldMarginTop = 0;
  var fieldMarginBottom = 2;

  if (desktop) {
    conditionalLabel = label;
    rightCol = 6;
    fieldMarginTop = 1.5;
    fieldMarginBottom = 1.5;
  }

  if (required) var requiredLabel = `${label} is required`;

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const MyTextfieldController = compact ? TextfieldControllerCompact : TextfieldController;


 
  
  return (
    <>
      <Grid container spacing={0}>
        {desktop && (
          <DesktopLabel
            name={name}
            displayLabel={displayLabel}
            popoverContent={popoverContent}
            handleOpenPopover={handleOpenPopover}
            handleClosePopover={handleClosePopover}
          />
        )}
        {!desktop && !compact && (
          <MobileLabel
            name={name}
            displayLabel={displayLabel}
            popoverContent={popoverContent}
            handleOpenPopover={handleOpenPopover}
            handleClosePopover={handleClosePopover}
          />
        )}
        <Grid item xs={rightCol}>
          <MyTextfieldController
            autoComplete={autoComplete}
            defaultValue={defaultValue}
            formatThousands={formatThousands}
            limitToTwoDecimalPlaces={limitToTwoDecimalPlaces}
            limitToIntegersBetween1And40={limitToIntegersBetween1And40}
            limitToAlphaNumericAndCapitalise={limitToAlphaNumericAndCapitalise}
            limitToLettersAndSpecialChars={limitToLettersAndSpecialChars}
            control={control}
            name={name}
            fieldRef={fieldRef}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            rows={rows}
            fieldMarginTop={fieldMarginTop}
            fieldMarginBottom={fieldMarginBottom}
            handleSaveOnBlur={handleSaveOnBlur}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            conditionalLabel={conditionalLabel}
            requiredLabel={requiredLabel}
            pattern={pattern}
            maxLength={maxLength}
            resolver={resolver}
          />
        </Grid>
      </Grid>
      <StyledPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        handleClosePopover={handleClosePopover}
        popoverContent={popoverContent}
      />
    </>
  );
};

TextFieldElement.propTypes = {
  autoComplete: PropTypes.string,
  defaultValue: PropTypes.string,
  formatThousands: PropTypes.bool,
  limitToTwoDecimalPlaces: PropTypes.bool,
  limitToIntegersBetween1And40: PropTypes.bool,
  limitToAlphaNumericAndCapitalise: PropTypes.bool,
  limitToLettersAndSpecialChars: PropTypes.bool,
  popoverContent: PropTypes.string,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  pattern: PropTypes.instanceOf(RegExp),
  maxLength: PropTypes.number,
  rows: PropTypes.number,
  resolver: PropTypes.func,
};

export default TextFieldElement;
