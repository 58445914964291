import React from "react";
import PropTypes from "prop-types";
//---- COMPONENTS ------------------//
//---- 3RD PARTY -------------------//
//---- MUI -------------------------//
import Typography from "@mui/material/Typography";
//---- MUI ICONS -------------------//
//---- CUSTOM HOOKS ----------------//
//---- FUNCTIONS -------------------//

StyledLabel.propTypes = {
  name: PropTypes.string,
  displayLabel: PropTypes.string,
};

//---- EXPORT DEFAULT FUNCTION -----//
export default function StyledLabel({name, displayLabel}) {
  //--- PROPS ------------------------//
  // Declare the PropTypes for each prop


  //--- SETTERS ----------------------//
  //--- CONSTANTS --------------------//
  //--- HANDLERS ---------------------//
  //--- CUSTOM HOOKS & USE EFFECT ----//
  //--- JSX --------------------------//
  return (
    <label htmlFor={name}>
      <Typography
        sx={{
          fontWeight: "500 !important",
          lineHeight: "normal !important",
          color: "#4F145B !important",
          margin: "0 !important",
          fontSize: "1.1rem !important",
        }}
      >
        {displayLabel}
      </Typography>
    </label>
  );
}
