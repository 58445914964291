import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Controller } from "react-hook-form";

export default function SelectElementController({ 
  control, 
  name, 
  localStorageValue, 
  handleSaveOnBlur, 
  startAdornment, 
  conditionalLabel, 
  requiredLabel, 
  generateSelectOptions, 
  defaultValue 
}) {
  

  const StartAdornment = () => {
    if (startAdornment) {
      return <InputAdornment position="start">{startAdornment}</InputAdornment>;
    }
    return null;
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={localStorageValue || defaultValue}
        render={({ field: { onBlur, onChange, value }, fieldState }) => (
          <TextField
          size="small"
            
            id={name}
            select
            margin="dense"
            fullWidth
            label={conditionalLabel}
            onBlur={() => {
              onBlur();
              handleSaveOnBlur(name, value);
            }}
            onChange={onChange}
            value={value}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            FormHelperTextProps={{
              sx: {
                mt: 0,
                mb: "0 !important",
                ml: "3px !important",
                mr: "3px !important",
                fontSize: "0.9rem !important",
                padding: "8px !important",
                fontWeight: "500 !important",
                color: "#ffffff !important",
                lineHeight: "normal !important",
                backgroundColor: "#A00202 !important",
              },
            }}
            InputProps={{
              endAdornment: null,
              startAdornment: <StartAdornment />,
              sx: {
                "& input": {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
                "& fieldset": {
                  paddingTop: 0,
                },
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#222 !important",
              },
            }}
          >
            {generateSelectOptions()}
          </TextField>
        )}
        rules={{ required: requiredLabel }}
      />
    </>
  );
}

SelectElementController.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  localStorageValue: PropTypes.string,
  fieldMarginTop: PropTypes.number,
  fieldMarginBottom: PropTypes.number,
  handleSaveOnBlur: PropTypes.func.isRequired,
  startAdornment: PropTypes.string,
  conditionalLabel: PropTypes.string.isRequired,
  requiredLabel: PropTypes.string,
  pattern: PropTypes.string,
  generateSelectOptions: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};
