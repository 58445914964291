import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";

//---- CONTEXT ---------------------//
import { DesktopContext } from "../../App";
import { CompactContext } from "../../App";
//---- 3RD PARTY -------------------//

//---- COMPONENTS ------------------//
import StyledPopover from "../popover/StyledPopover";
import DesktopLabel from "../label/DesktopLabel";
import MobileLabel from "../label/MobileLabel";
import SelectElementController from "./SelectElementController";
import SelectElementControllerCompact from "./SelectElementControllerCompact";

//---- MUI -------------------------//
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";

//---- MUI ICONS -------------------//
//---- CUSTOM HOOKS ----------------//
//---- FUNCTIONS -------------------//
import { handleSaveOnBlur } from "../../utils/miscFunctions";

  // Declare the PropTypes for each prop
  SelectElement.propTypes = {
    popoverContent: PropTypes.string,
    startAdornment: PropTypes.string,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    pattern: PropTypes.string,
    rows: PropTypes.number,
    options: PropTypes.array.isRequired,
    defaultValue: PropTypes.string,
  };
//---- EXPORT DEFAULT FUNCTION -----//

export default function SelectElement({popoverContent, startAdornment, name, control, label, required, placeholder, disabled, pattern, rows, options, defaultValue}) {



  const compact = useContext(CompactContext);
  //const compact = false;
  //const SelectElementController = compact ? compactController : fatController;



  //--- PROPS ------------------------//


  //--- STATES -----------------------//
  const [anchorEl, setAnchorEl] = useState(null);

  //--- CONSTANTS --------------------//
  const fieldRef = useRef();
  const desktop = useContext(DesktopContext);
  const open = Boolean(anchorEl);
  //const id = open ? "simple-popover" : undefined;
  const localStorageValue = localStorage.getItem(name);
  var conditionalLabel = label;
  var displayLabel = label;

  var rightCol = 12;
  var fieldMarginTop = 0;
  var fieldMarginBottom = 2;

  if (desktop) {
    conditionalLabel = label;

    //if (required) displayLabel = displayLabel + " *";
    rightCol = 6;
    fieldMarginTop = 1.5;
    fieldMarginBottom = 1.5;
  }

  if (required) var requiredLabel = label + " is required";

  //--- HANDLERS ---------------------//
  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  //--- CUSTOM HOOKS & USE EFFECT ----//
  //--- JSX --------------------------//

var MySelectElementController = compact ? SelectElementControllerCompact : SelectElementController;


  
  const generateSelectOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{
            fontSize: "0.85rem !important",
          }}
        >
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <>
      <Grid container spacing={0}>
        {desktop && (
          <DesktopLabel
            name={name}
            displayLabel={displayLabel}
            popoverContent={popoverContent}
            handleOpenPopover={handleOpenPopover}
            handleClosePopover={handleClosePopover}
          />
        )}
        {!desktop && !compact && (
          <MobileLabel
            name={name}
            displayLabel={displayLabel}
            popoverContent={popoverContent}
            handleOpenPopover={handleOpenPopover}
            handleClosePopover={handleClosePopover}
          />
        )}
        <Grid item xs={rightCol}>
          <MySelectElementController
            control={control}
            name={name}
            localStorageValue={localStorageValue}
            fieldRef={fieldRef}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            rows={rows}
            fieldMarginTop={fieldMarginTop}
            fieldMarginBottom={fieldMarginBottom}
            handleSaveOnBlur={handleSaveOnBlur}
            startAdornment={startAdornment}
            conditionalLabel={conditionalLabel}
            requiredLabel={requiredLabel}
            pattern={pattern}
            generateSelectOptions={generateSelectOptions}
            defaultValue={defaultValue}
          />
        </Grid>
      </Grid>
      <StyledPopover
        open={open}
        anchorEl={anchorEl}
        handleClosePopover={handleClosePopover}
        popoverContent={popoverContent}
      />
    </>
  );
}