import React from "react";
import PropTypes from 'prop-types';

//---- COMPONENTS ------------------//
//---- 3RD PARTY -------------------//
//---- MUI -------------------------//
import Grid from "@mui/material/Grid";
import StyledLabel from "./StyledLabel";
import MoreInfoPopoverLink from "../popover/MoreInfoPopoverLink";
//---- MUI ICONS -------------------//
//---- CUSTOM HOOKS ----------------//
//---- FUNCTIONS -------------------//
  // Declare the PropTypes for each prop
  DesktopLabel.propTypes = {
    name: PropTypes.string,
    displayLabel: PropTypes.string,
    popoverContent: PropTypes.string,
    handleOpenPopover: PropTypes.func,
    handleClosePopover: PropTypes.func,
  };

//---- EXPORT DEFAULT FUNCTION -----//
export default function DesktopLabel({name, displayLabel, popoverContent, handleOpenPopover, handleClosePopover}) {
  //--- PROPS ------------------------//



  //--- SETTERS ----------------------//
  //--- CONSTANTS --------------------//
  //--- HANDLERS ---------------------//
  //--- CUSTOM HOOKS & USE EFFECT ----//
  //--- JSX --------------------------//
  return (
    <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          paddingRight: '16px',
          marginLeft: '8px',
        }}
      >
        <StyledLabel name={name} displayLabel={displayLabel} />
        {popoverContent && (
          <MoreInfoPopoverLink
 
            handleClosePopover={handleClosePopover}
            handleOpenPopover={handleOpenPopover}
          />
        )}
      </div>
    </Grid>
  );
}
