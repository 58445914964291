import React from "react";
import PropTypes from "prop-types";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

export default function StyledPopover({ open, anchorEl, popoverContent, handleClosePopover }) {
  // Use a function to create an object with __html key
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: "none",
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        style: { width: "300px" },
      }}
      onClose={handleClosePopover}
      disableRestoreFocus
    >
      {/* Use dangerouslySetInnerHTML to set HTML content */}
      <Typography sx={{ p: 1 }} dangerouslySetInnerHTML={createMarkup(popoverContent)} />
    </Popover>
  );
}

StyledPopover.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  popoverContent: PropTypes.string,
  handleClosePopover: PropTypes.func,
};
