import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { Controller } from "react-hook-form";

export default function SelectElementController({
  control,
  name,
  localStorageValue,
  fieldMarginTop,
  fieldMarginBottom,
  handleSaveOnBlur,
  startAdornment,
  conditionalLabel,
  requiredLabel,
  generateSelectOptions,
  defaultValue,
  placeholder,
}) {

  const StartAdornment = () => {
    if (startAdornment) {
      return <InputAdornment position="start">{startAdornment}</InputAdornment>;
    }
    return null;
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={localStorageValue || defaultValue}
        render={({ field: { onBlur, onChange, value }, fieldState }) => (
          <TextField
            sx={{
              "& label": {
                display: "none",
              },
              mt: fieldMarginTop,
              mb: fieldMarginBottom,
            }}
            id={name}
            select
            margin="normal"
            fullWidth
            label={conditionalLabel}
            onBlur={() => {
              onBlur();
              handleSaveOnBlur(name, value);
            }}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            SelectProps={{
              // Add this block
              displayEmpty: true,
            }}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            FormHelperTextProps={{
              sx: {
                mt: 0,
                mb: "0 !important",
                ml: "3px !important",
                mr: "3px !important",
                fontSize: "0.9rem !important",
                padding: "8px !important",
                fontWeight: "500 !important",
                color: "#ffffff !important",
                lineHeight: "normal !important",
                backgroundColor: "#A00202 !important",
              },
            }}
            InputProps={{
              notched: false,
              endAdornment: null,
              startAdornment: <StartAdornment />,
              sx: {
                "& input": {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
                "& fieldset": {
                  paddingTop: 0,
                },
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value="" disabled >
              {placeholder}
            </MenuItem>
            {generateSelectOptions()}
          </TextField>
        )}
        rules={{ required: requiredLabel }}
      />
    </>
  );
}

SelectElementController.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  localStorageValue: PropTypes.string,
  fieldMarginTop: PropTypes.number,
  fieldMarginBottom: PropTypes.number,
  handleSaveOnBlur: PropTypes.func.isRequired,
  startAdornment: PropTypes.string,
  conditionalLabel: PropTypes.string.isRequired,
  requiredLabel: PropTypes.string,
  generateSelectOptions: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
};
